<template>
  <div class="article-detail">
    <!-- 上 -->
    <div>
      <div class="dw-center">
        <div class="ad-up content-wth top18-center">
          <!-- 导航 -->
          <V-ArticleNav :navList="navList"></V-ArticleNav>
          <!-- 作者 时间-->
          <div class="font-14 author-time xiugai">
            <!-- 标题 -->
            <div class="article-title">{{ article.title }}</div>
            <!-- 作者 时间-->
            <div style="margin-top: 15px">
              <span class="author">本文作者： {{ article.authorName }}</span>
              <span class="time">{{ article.createTime }}</span>
            </div>
            <!-- 文章导语 -->
            <p class="intro font-14">{{ article.intro }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 下 -->
    <div class="ad-down">
      <!-- 左 -->
      <div class="ad-left content-wth">
        <!-- 文章内容 -->
        <!-- <div>
          <img alt="图片加载失败" :src="article.img"/>
        </div>-->
        <div class="ql-editor content-info" v-html="article.content"></div>

        <div
          style="color: #0084ff; font-size: 15px; cursor: pointer"
          v-show="article.ISLICode != '' && article.ISLICode != null"
          @click="see(article.accessory)"
        >
          <span>{{ article.ISLICode }}</span>
          <!-- 编码 -->
        </div>
        <span
          style="color: #0084ff; font-size: 15px; cursor: pointer"
          v-show="
            article.accessory != '' &&
            article.accessory != null &&
            article.ISLICode == ''
          "
          @click="see(article.accessory)"
        >
          <span>查看附件</span>
          <!-- 附件 -->
        </span>
        <a :href="article.reference" target="_blank" v-show="article.reference">
          链接查看地址：
          <span style="color: #0e86f6; cursor: pointer; font-size: 13px">{{
            article.reference
          }}</span>
        </a>
        <!-- 收藏点赞 分享 -->
        <div class="collect-praise-share">
          <div class="collect-praise">
            <!--是否收藏的图标切换-->
            <div
              class="collect"
              @click="collectArticle()"
              style="vertical-align: 0px"
            >
              <span
                class="collect-icon"
                v-if="isCollect <= 0"
                style="margin-left: 8px"
              ></span>
              <span
                class="collect-icon1"
                v-if="isCollect > 0"
                style="margin-left: 8px"
              ></span>
              <span style="margin-left: 8px"
                >{{ article.collectNum }}人收藏</span
              >
            </div>
            <div
              class="praise"
              @click="praiseArticle()"
              style="vertical-align: 0px"
            >
              <span>{{ article.praiseNum }}</span>
              <span
                class="refresh-icon1"
                v-if="fabul <= 0"
                style="vertical-align: 2px; margin-left: 8px"
              ></span>
              <span
                class="refresh1-icon1"
                v-if="fabul > 0"
                style="vertical-align: 2px; margin-left: 8px"
              ></span>
            </div>
          </div>
          <!-- <div class="share">
            <span>分享:</span>
            <span class="xinlang-icon"></span>
            <span class="weixin-icon"></span>
            <span class="fxadd-icon"></span>
          </div>-->
        </div>
        <!-- 相关文章 -->
        <div class="relation-article">
          <span style="font-size: 20px">相关文章</span>
          <hr />
          <div class="key-word">
            <span
              v-for="(one, index) in keyword"
              :key="index"
              style="cursor: pointer"
              @click="keyWordSearch(one)"
              >{{ one }}</span
            >
          </div>
          <div class="relation-list">
            <div
              class="relation-one"
              v-for="oneRelation in relationArticle"
              :key="oneRelation.id"
            >
              <V-UppicDowntext
                @faoul="faoul"
                @cc="cc"
                @inits="inits"
                @handInitCollent="initCollect"
                :imgUrl="oneRelation.coverImg"
                :id="oneRelation.id"
                :hotNum="oneRelation.hotNum"
                :title="oneRelation.title"
                imgHeight="98px"
                fontSize="14px"
              ></V-UppicDowntext>
            </div>
          </div>
        </div>
        <!-- 文章点评 -->
        <div>
          <V-Comment :articleId="id" :replyList="replyList"></V-Comment>
        </div>
      </div>
      <!-- 右 -->
      <div class="ad-right">
        <!-- 作者信息 -->
        <!-- <div class="author">

                 <V-AuthorIntro @faoul="faoul" :img="author.img" :author="author" :degree="author.degree" :intro="author.intro" :articleType="articleType" :list="list"></V-AuthorIntro>
        </div>-->
        <!-- 作者信息 -->
        <div class="author">
          <V-AuthorIntro
            @faoul="faoul"
            @thisMonHot="isCAShow"
            :img="author.img"
            :author="author"
            :articleType="articleType"
            :list="list"
            @inits="inits"
          ></V-AuthorIntro>
        </div>
        <!-- 最新文章 -->
        <div></div>
        <div class="newest">
          <div class="bt">最新文章</div>
          <div class="newest-list">
            <div
              class="newest-one"
              @click="goToArticleDetail(newOneArticle.id, newOneArticle.hotNum)"
              v-for="newOneArticle in newestArticle"
              :key="newOneArticle.id"
            >
              <span
                style="width: 100%; display: inline-block; text-align: center"
                >{{ newOneArticle.title }}</span
              >
              <!-- {{newOneArticle.coverImg}} -->
              <img style="width: 100%" :src="newOneArticle.coverImg" />
            </div>
          </div>
        </div>
        <div class="release-amount">
          <span>
            累计已发布
            <span>{{ articleNum }}</span> 篇文章
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="gotop" v-show="gotop" @click="toTop">Top</div> -->
  </div>
</template>

<script>
import UppicDowntext from "../../components/uppic-downtext/index.vue";
import AuthorIntro from "../../components/author-intro/index.vue";
import Comment from "../../components/article-detail/comment/index.vue";
import ArticleNav from "../../components/article-nav/index.vue";
import {
  dataFormat,
  updateHotnumFn,
  formatDate,
  showTimeFormat,
  integralAddOne,
} from "@/js/common/index.js";
import { DELETE, QUERY, QUERYED, UPDATE, INSERT } from "@/services/dao.js";
export default {
  data() {
    return {
      id: -1,
      authorId: -1,
      fabul: 0,
      isCollect: 0,
      articleNum: "",
      //newestArticle:[{id:6,title:"arXiv 每日论文集 117 篇 03.24 更新"},{id:7,title:"嘎嘎嘎嘎嘎过333"}],
      newestArticle: [],
      replyList: [], //评论数组
      article: {
        id: -1,
        accessory: "",
        authorName: "",
        browseNum: "",
        collectNum: 0,
        commentNum: 0,
        concernNum: "0",
        content: "",
        keyword: "",
        praiseNum: 0,
        reference: "",
        theme_id: "",
        title: "",
        img: "",
        ISLICode: "", //编码
      },
      // relationArticle:[{img:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1586522731866&di=c8eb6a274e915136eab1b885ddc895ed&imgtype=0&src=http%3A%2F%2Fpic.feizl.com%2Fupload%2Fallimg%2F170615%2F143633G57-0.jpg",id:11, title:"看起有啥子感觉"},{img:"https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1586522731866&di=c8eb6a274e915136eab1b885ddc895ed&imgtype=0&src=http%3A%2F%2Fpic.feizl.com%2Fupload%2Fallimg%2F170615%2F143633G57-0.jpg",id:189, title:"你说吧美不美"}],
      relationArticle: [],
      author: {
        id: -1,
        name: "",
        intro: "",
        degree: "",
        img: "",
        articleNum: "",
      },
      articleType: "热门文章",
      //list:[{id:1,title:"AI 崛起的第九个年头，还有哪些大有可为的地方？"},{id:2,title:"AI 崛起的第九个年头，还有哪些大有可为的地方？"}],
      list: [],
      navList: [{ word: "正文" }],
      gotop: false,
    };
  },
  created() {
    this.id = Number(this.$route.query.id);
    //显示点赞状态
    this.faoul();
    this.init();
    // this.$store.state.el_.scrollTop = 0; // initScrollTop
  },

  updated() {
    // 调整v-html绑定的后台返回的富文本中p标签的字体样式
    let e = document.querySelectorAll(".content-info p");
    for (let i = 0; i < e.length; i++) {
      if (e[i].childNodes.length == 1 || e[i].hasChildNodes("<br>")) {
        e[i].style.fontSize = "16px";
        e[i].style.lineHeight = "35px";
      }
    }
    // 调整v-html绑定的后台返回的富文本中img显示格式
    let img = document.querySelectorAll(".content-info img");
    for (let i = 0; i < img.length; i++) {
      img[i].style.maxWidth = "100%";
      img[i].style.height = "auto";
    }
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.id = Number(this.$route.query.id);
        this.init(); //重新加载数据
      }
    },
  },
  computed: {
    keyword: function () {
      if (this.article.keyword == "" || !this.article.keyword) {
        return [];
      }
      return JSON.parse(this.article.keyword);
    },
  },
  methods: {
    //显示评论
    async inits() {
      this.replyList = [];
      // this.id = Number(this.$route.query.id);
      // console.log("评论文章ID" + this.id);
      //显示一级评论
      //根据文章ID，知识头条的一级评论
      let info = await QUERYED(
        "post",
        "",
        "   TTOneComment(where: {whole: {_eq: " +
          this.id +
          "}}) {   ancestorCommentId    articleImg  articleTitle    whole   time   parentCommentId   isShield   id   content    commentator   authorName authorImg   authorId  articleintro  }"
      );
      for (let i = 0; i < info.data.TTOneComment.length; i++) {
        let a = info.data.TTOneComment[i];
        a.authorImg = this.api.LoginURL.concat(a.authorImg);
        a.time = formatDate(Date.parse(a.time));
        //显示二级评论
        let info_c = await QUERYED(
          "post",
          "",
          "   TTTwoCommentS(where: {whole: {_eq: " +
            this.id +
            "}, ancestorCommentId: {_eq: " +
            a.id +
            "}}) {  type    time   parentCommentId   isShield  id   content   commentator  authorName   authorImg   authorId   articleintro   articleTitle   articleImg  ancestorCommentId  UserauthorImg   UserauthorId   UserauthorName }"
        );
        a.children = info_c.data.TTTwoCommentS;
        a.children.time = formatDate(Date.parse(a.children.time));
        this.replyList.push(a);
      }
    },
    // 获取scrollTop
    // handleScroll(){
    //   let scrolltop = this.$store.state.el_.scrollTop;
    //   scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    // },
    // goTop
    // toTop() {
    //   let top = this.$store.state.el_.scrollTop;
    //   // 实现滚动效果
    //   const timeTop = setInterval(() => {
    //     this.$store.state.el_.scrollTop = top -= 200;
    //     if (top <= 0) {
    //       clearInterval(timeTop);
    //     }
    //   }, 10);
    // },
    async see(answerFiles) {
      //  window.URL.createObjectURL=answerFiles;
      //  window.location.href =this.api.LoginURL.concat( answerFiles);
      window.open(this.api.LoginURL.concat(answerFiles), "_blank"); ///新建窗口
    },
    init() {
      this.initArticledetail();
      // this.initAuthor();
      // 初始化该篇文章是否已被当前用户收藏
      this.initCollect();
      this.inits(); //刷新评论
    },
    //获取文章详细信息
    async initArticledetail() {
      // this.id = Number(this.$route.query.id);
      let info = await QUERY(
        "post",
        "",
        "  Article(where: {id: {_eq: " +
          this.id +
          "}}) {    id    img ISLICode    intro coverImg   title    authorName    createTime    authorId    accessory  browseNum    collectNum    commentNum    content    hotNum    isChoiceness    isRecommend    keyword    praiseNum    reference    theme  }"
      );
      console.log(info.data.Article,'测试');
      this.article.id = info.data.Article[0].id;
      this.article.accessory = info.data.Article[0].accessory;
      this.article.browseNum = info.data.Article[0].browseNum;
      this.article.collectNum = info.data.Article[0].collectNum;
      this.article.commentNum = info.data.Article[0].commentNum;
      // eslint-disable-next-line no-useless-escape
      // this.article.content = info.data.Article[0].content.replace(
      //   /[\'\"\\\\]/g,
      //   ""
      // );
      this.article.content =
        info.data.Article[0].coverImg.substring(0, 7) == "http://"
          ? info.data.Article[0].content
          : info.data.Article[0].content.replace(/[\'\"\\\\]/g, "");
      this.article.hotNum = info.data.Article[0].hotNum;
      this.article.img = this.api.LoginURL.concat(info.data.Article[0].img);
      this.article.intro = info.data.Article[0].intro;
      this.article.keyword = info.data.Article[0].keyword;
      this.article.praiseNum = info.data.Article[0].praiseNum;
      this.article.reference = info.data.Article[0].reference;
      this.article.theme_id = info.data.Article[0].theme;
      this.article.title = info.data.Article[0].title;
      this.article.authorName = info.data.Article[0].authorName;
      this.article.accessory = info.data.Article[0].accessory; ///附件
      this.article.ISLICode = info.data.Article[0].ISLICode; // 编码
      this.article.createTime = showTimeFormat(info.data.Article[0].createTime);
      this.authorId = info.data.Article[0].authorId;

      this.initAuthor();
      let keywordParam = false;
      this.keyword.forEach((item, index) => {
        if (index == 0) {
          keywordParam = 'keyword: {_like: "%' + item + '%"}';
        } else {
          keywordParam =
            keywordParam + ', _or: {keyword: {_like: "%' + item + '%"}}';
        }
      });
      this.relationArticle = [];
      let kerwordInfo = await QUERY(
        "post",
        "",
        "  Article(limit: 4, distinct_on: id) { id   coverImg  img    title   hotNum  }"
      );
      for (let i = 0; i < kerwordInfo.data.Article.length; i++) {
        let item = kerwordInfo.data.Article[i]
        item.coverImg = item.coverImg.indexOf('http')>-1?item.coverImg:this.api.LoginURL.concat(item.coverImg)
        // a = kerwordInfo.data.Article[i];
        // (a.coverImg =
        //   a.coverImg.substring(0, 7) == "http://"
        //     ? a.coverImg
        //     : this.api.LoginURL.concat(a.coverImg)),
          // a.coverImg = this.api.LoginURL.concat(a.coverImg);
          // //    console.log("relationArticle:  ")
          // //    console.log(this.relationArticle)
          this.relationArticle.push(item);
      }
      this.initHotArticle();
      this.initNewArticle();
    },
    //获取作者（用户）详细信息
    async initAuthor() {
      let info = await QUERYED(
        "post",
        "",
        "  PCUsers(where: {id: {_eq: " +
          this.authorId +
          "}}) {    id    img    intro    articleNum    beCommentedNum    commentToNum    concernNum    email    industry    level    name    praiseNum    wxName    xlName  }"
      );
      this.author.id = info.data.PCUsers[0].id;
      //alert(this.author.id)
      this.author.name = info.data.PCUsers[0].name;
      this.author.img = this.api.LoginURL.concat(info.data.PCUsers[0].img);
      this.author.intro = info.data.PCUsers[0].intro;
      this.author.degree = info.data.PCUsers[0].industry;

      // this.articleNum=info.data.User[0].articleNum;
      //显示发布文章数
      let data = await QUERYED(
        "post",
        "",
        "  Article_aggregate ( where: {userid: {_eq: " +
          this.authorId +
          "}}){    aggregate {      count    }  }"
      );
      this.articleNum = data.data.Article_aggregate.aggregate.count;

      this.author.articleNum = info.data.User[0].articleNum;
    },
    async initHotArticle() {
      let info = await QUERYED(
        "post",
        "",
        "HotArticle(where: {authorId: {_eq: " +
          this.authorId +
          "}}, limit:5) {    id    title  hotNum}"
      );
      this.list = info.data.HotArticle;
      //   console.log('this.list: ')
      //   console.log(info)
    },
    async initNewArticle() {
      let a = null;
      this.newestArticle = [];
      let info = await QUERYED(
        "post",
        "",
        "Article(where: {authorId: {_eq: " +
          this.authorId +
          "}},limit:5, distinct_on: id) {  coverImg  id    title    hotNum}"
      );
      for (let i = 0; i < info.data.Article.length; i++) {
        // a = info.data.Article[i];
        // a.coverImg= this.api.LoginURL.concat(a.coverImg);
        // this.newestArticle.push(a)

        a = info.data.Article[i];
        if(a.coverImg&&a.coverImg!='null'){
          a.coverImg = a.coverImg.indexOf('http')>-1
              ? a.coverImg
              : this.api.LoginURL.concat(a.coverImg);
        }else{
          a.coverImg = ''
        }
        // a.coverImg = this.api.LoginURL.concat(a.coverImg);
        this.newestArticle.push(a);
      }
      //   console.log(this.newestArticle)
    },
    //跳转到文章详情
    async goToArticleDetail(id, hotNum) {
      // console.log("202: "+id);
      // let info=  await  QUERY("post","",'Article(where: {authorId: {_eq: '+this.authorId+'}},limit:5) {    id    title    hotNum}');
      // console.log("268: "+hotNum)

      //     hotNum+=1
      //    let update_hotNum = await UPDATE("POST","",'update_TtArticle(where:{id:{_eq:'+id+'}},_set:{hotNum:'+hotNum+'})  {affected_rows}')
      //    console.log(update_hotNum)

      // goTop
      // let top = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("top",top);
      // document.body.scrollTop = document.documentElement.scrollTop = this.goTop;
      // console.log("2222")
      // this.toTop(); //scrollTop为0
      updateHotnumFn(id, hotNum); //修改浏览数
      let user = this.$store.state.userinfo.id;
      if (this.$store.state.userinfo.id) {
        integralAddOne(user); //积分加1
        this.$router.push({
          path: "/articleDetail",
          query: {
            id: id,
          },
        });
        let isCot = await QUERYED(
          "post",
          "",
          "Collect(where:{user:{_eq:" +
            this.user +
            "},article:{_eq:" +
            id +
            "}}) {id}"
        );
        // console.log(isCot)
        this.isCollect = isCot.data.Collect.length;
      } else {
        this.$router.push({
          path: "/articleDetail",
          query: {
            id: id,
          },
        });
      }

      // this.init();

      // console.log(this.isCollect)
    },
    // 判断当月热门文章是否被当前用户收藏
    async isCAShow(id) {
      // console.log("AuthorIntro: "+id)
      if (this.user != undefined) {
        let isCot = await QUERYED(
          "post",
          "",
          "Collect(where:{user:{_eq:" +
            this.user +
            "},article:{_eq:" +
            id +
            "}}){id}"
        );
        this.isCollect = isCot.data.Collect.length;
      }
    },
    keyWordSearch(word) {
      // console.log(111111111111);
      this.$router.push({
        path: "/keywordArticle",
        query: {
          searchWord: word,
        },
      });
    },
    //初始化该文章是否被当前用户收藏
    async initCollect(uppicId) {
      // console.log("子组件： "+uppicId)
      //获取登陆id
      this.user = this.$store.state.userinfo.id;
      // console.log(this.$store.state.userinfo.id)
      //文章id 优先获取子组件传过来的文章id
      this.id = uppicId || Number(this.$route.query.id);
      // console.log('最新文章ID: '+this.id)
      // console.log("213: "+this.user,this.id)
      let data = null;
      if (this.user < 0 || this.user == "" || this.user == null) {
        // 没有登陆则收藏icon肯定不点亮
        // this.fabul=0
        this.isCollect = 0;
      } else {
        // 已收藏则icon点亮
        data = await QUERYED(
          "post",
          "",
          "   Collect(where: {user: {_eq: " +
            this.user +
            "}, article: {_eq: " +
            this.id +
            "}}) { id  }"
        );
        // console.log(data)
        this.isCollect = data.data.Collect.length;
      }
    },
    //点击收藏----
    async collectArticle() {
      let articleId = this.article.id;
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      this.id = Number(this.$route.query.id);
      let info = null;

      let datas = null;
      //查询收藏数
      let data = await QUERYED(
        "post",
        "",
        "  Article(where: {id: {_eq: " + this.id + "}}) {   collectNum      }"
      );
      // console.log(data)e
      //alert(data.data.Article[0].praiseNum)
      // console.log(data)
      if (this.user < 0 || this.user == "" || this.user == null) {
        //alert("请您先登录！");
        this.$message({
          message: "请您先登录！",
          type: "warning",
        });
      } else {
        if (this.isCollect <= 0) {
          //收藏
          info = await INSERT(
            "POST",
            "",
            " insert_TtCollect(objects: {user: " +
              this.user +
              ', time: "' +
              new Date() +
              '", article: ' +
              this.id +
              "}) { affected_rows  }"
          );
          // console.log("262"+info)
          if (info.data.insert_TtCollect.affected_rows > 0) {
            // 查询是否被收藏
            datas = await QUERYED(
              "post",
              "",
              "   Collect(where: {user: {_eq: " +
                this.user +
                "}, article: {_eq: " +
                this.id +
                "}}) { id  }"
            );
            this.isCollect = datas.data.Collect.length;
            //  收藏数+1
            data.data.Article[0].collectNum += 1;
            //修改文章表中的收藏数
            let datdeds = await UPDATE(
              "post",
              "",
              "update_TtArticle(where: {id: {_eq: " +
                this.id +
                "}}, _set: {collectNum: " +
                data.data.Article[0].collectNum +
                "}) { affected_rows }"
            );
            if (datdeds.data.update_TtArticle.affected_rows > 0) {
              //   console.log(this.datdeds)
              //更新显示收藏状态
              this.initCollect();
              //更新文章详情
              this.fabuled();
              integralAddOne(this.user); //收藏积分加1
            }
          }
        } else {
          // 取消收藏
          info = await DELETE(
            "post",
            "",
            "   delete_TtCollect(where: {user: {_eq: " +
              this.user +
              "}, article: {_eq: " +
              this.id +
              "}}) {   affected_rows  }"
          );
          if (info.data.delete_TtCollect.affected_rows > 0) {
            ///查询是否被收藏
            datas = await QUERYED(
              "post",
              "",
              "   Collect(where: {user: {_eq: " +
                this.user +
                "}, article: {_eq: " +
                this.id +
                "}}) { id  }"
            );
            this.isCollect = datas.data.Collect.length;
            //    判断收藏数
            if (data.data.Article[0].collectNum == 0) {
              data.data.Article[0].collectNum == 0;
            } else {
              data.data.Article[0].collectNum -= 1;
              //修改文章表中的收藏数
              let datdeds = await UPDATE(
                "post",
                "",
                "  update_TtArticle(where: {id: {_eq: " +
                  this.id +
                  "}}, _set: {collectNum: " +
                  data.data.Article[0].collectNum +
                  "}) { affected_rows }"
              );
              if (datdeds.data.update_TtArticle.affected_rows > 0) {
                //   console.log(this.datdeds)
                //更新显示收藏状态
                this.initCollect();
                //更新文章详情
                this.fabuled();
              }
            }
          }
        }
      }
    },
    ///点赞、收藏刷新
    async fabuled() {
      let info = await QUERYED(
        "post",
        "",
        "  Article(where: {id: {_eq: " +
          this.id +
          "}}) {    id    img ISLICode  coverImg  intro    title    authorName    createTime    authorId    accessory  browseNum    collectNum    commentNum    content    hotNum    isChoiceness    isRecommend    keyword    praiseNum    reference    theme  }"
      );
      this.article.id = info.data.Article[0].id;
      this.article.accessory = info.data.Article[0].accessory;
      this.article.browseNum = info.data.Article[0].browseNum;
      this.article.collectNum = info.data.Article[0].collectNum;
      this.article.commentNum = info.data.Article[0].commentNum;
      // eslint-disable-next-line no-useless-escape
      // console.log(info.data.Article[0].content);
      // this.article.content = info.data.Article[0].content.replace(
      //   /[\'\"\\\\]/g,
      //   ""
      // );
      (this.article.content =
        info.data.Article[0].coverImg.substring(0, 7) == "http://"
          ? info.data.Article[0].content
          : info.data.Article[0].content.replace(/[\'\"\\\\]/g, "")),
        (this.article.hotNum = info.data.Article[0].hotNum);
      this.article.img = this.api.LoginURL.concat(info.data.Article[0].img);
      this.article.intro = info.data.Article[0].intro;
      this.article.keyword = info.data.Article[0].keyword;
      this.article.praiseNum = info.data.Article[0].praiseNum;
      this.article.reference = info.data.Article[0].reference;
      this.article.theme_id = info.data.Article[0].theme;
      this.article.title = info.data.Article[0].title;
      this.article.authorName = info.data.Article[0].authorName;
      this.article.accessory = info.data.Article[0].accessory; ///附件
      this.article.ISLICode = info.data.Article[0].ISLICode; // 编码
      this.article.createTime = showTimeFormat(info.data.Article[0].createTime);
      this.authorId = info.data.Article[0].authorId;
    },
    //显示点赞
    async faoul() {
      //获取登陆id
      this.user = this.$store.state.userinfo.id;
      //文章id
      this.id = Number(this.$route.query.id);
      let data = null;
      if (this.user < 0 || this.user == "" || this.user == null) {
        this.fabul = 0;
      } else {
        data = await QUERYED(
          "post",
          "",
          "   TTPraise(where: {user: {_eq: " +
            this.user +
            "}, article: {_eq: " +
            this.id +
            "}}) { id  }"
        );
        this.fabul = data.data.TTPraise.length;
      }
    },
    cc() {
      // console.log("222222");
      location.reload();
    },
    //点赞
    async praiseArticle() {
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      this.id = Number(this.$route.query.id);
      let info = null;
      let data = null;
      let datas = null;
      //查询点赞数
      data = await QUERYED(
        "post",
        "",
        "  Article(where: {id: {_eq: " + this.id + "}}) {   praiseNum      }"
      );
      if (this.user < 0 || this.user == "" || this.user == null) {
        //alert("请您先登录！");
        this.$message({
          message: "请您先登录！",
          type: "warning",
        });
      } else {
        if (this.fabul <= 0) {
          //点赞
          info = await INSERT(
            "POST",
            "",
            " insert_TtPraise(objects: {user: " +
              this.user +
              ', time: "' +
              new Date() +
              '", article: ' +
              this.id +
              "}) { affected_rows  }"
          );
          if (info.data.insert_TtPraise.affected_rows > 0) {
            ///查询是否被点赞
            datas = await QUERYED(
              "post",
              "",
              "   TTPraise(where: {user: {_eq: " +
                this.user +
                "}, article: {_eq: " +
                this.id +
                "}}) { id  }"
            );
            this.fabul = datas.data.TTPraise.length;
            //  点赞数+1
            data.data.Article[0].praiseNum += 1;
            //修改文章表中的点赞数
            let datdeds = await UPDATE(
              "post",
              "",
              "  update_TtArticle(where: {id: {_eq: " +
                this.id +
                "}}, _set: {praiseNum: " +
                data.data.Article[0].praiseNum +
                "}) { affected_rows }"
            );
            if (datdeds.data.update_TtArticle.affected_rows > 0) {
              // console.log(this.datdeds);
              //更新显示点赞状态
              this.faoul();
              //更新文章详情
              this.fabuled();
            }
          }
        } else {
          // 取消点赞
          info = await DELETE(
            "post",
            "",
            "   delete_TtPraise(where: {user: {_eq: " +
              this.user +
              "}, article: {_eq: " +
              this.id +
              "}}) {   affected_rows  }"
          );
          if (info.data.delete_TtPraise.affected_rows > 0) {
            ///查询是否被点赞
            datas = await QUERYED(
              "post",
              "",
              "   TTPraise(where: {user: {_eq: " +
                this.user +
                "}, article: {_eq: " +
                this.id +
                "}}) { id  }"
            );
            this.fabul = datas.data.TTPraise.length;
            //    判断点赞数
            if (data.data.Article[0].praiseNum == 0) {
              data.data.Article[0].praiseNum == 0;
            } else {
              data.data.Article[0].praiseNum -= 1;
              //修改文章表中的点赞数
              let datdeds = await UPDATE(
                "post",
                "",
                "  update_TtArticle(where: {id: {_eq: " +
                  this.id +
                  "}}, _set: {praiseNum: " +
                  data.data.Article[0].praiseNum +
                  "}) { affected_rows }"
              );
              if (datdeds.data.update_TtArticle.affected_rows > 0) {
                // console.log(this.datdeds);
                //更新显示点赞状态
                this.faoul();
                //更新文章详情
                this.fabuled();
              }
            }
          }
        }
      }
    },
  },
  components: {
    "V-UppicDowntext": UppicDowntext,
    "V-AuthorIntro": AuthorIntro,
    "V-Comment": Comment,
    "V-ArticleNav": ArticleNav,
  },
};
</script>

<style scoped>
/* 图标 */

.refresh-icon1 {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../assets/icon/Fabulous.png");
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  /*left: 5px;
    margin-right: 5px; */
  /* border: 1px solid red; */
  background-size: 15px 15px;
  margin-left: 5px;
}
.refresh1-icon1 {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../assets/icon/Fabuloused1.png");
  background-repeat: no-repeat;
  position: relative;
  top: 3px;
  /* left: 5px; */
  /* margin-right: 5px; */
  background-size: 15px 15px;
  margin-left: 5px;
}
/*点击收藏的div*/
.collect-praise .collect {
  cursor: pointer;
}
/*收藏未点亮icon*/
.collect-icon {
  background: url(../../assets/img/icon-collect.png) no-repeat;
}
/*收藏点亮icon*/
.collect-icon1 {
  background: url(../../assets/img/icon-collected.png) no-repeat;
}
.collect-person {
  vertical-align: 0px;
}
/*2个收藏icon的公共样式*/
.collect .collect-icon,
.collect-icon1 {
  display: inline-block;
  height: 15px;
  width: 15px;
  vertical-align: -2px;
  background-size: 15px 15px;
}
/* 公共 */
.font-14 {
  font-size: 14px;
}
.content-wth {
  width: 728px;
  word-break: break-all;
}
.collect-icon {
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/icon-collect.png);
  display: inline-block;
}
.praise-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 629px;
  background-position-y: -44px;
  display: inline-block;
}
.home-icon {
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 502px;
  background-position-y: -75px;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.xinlang-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 615px;
  background-position-y: -565px;
}
.weixin-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 485px;
  background-position-y: -564px;
}
.fxadd-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 0px solid rgba(155, 170, 182, 1);
  border-radius: 50%;
  background-image: url(../../assets/icon/icon.png);
  background-position-x: 549px;
  background-position-y: -564px;
}
.article-detail,
.dw-center {
  text-align: center;
}
.article-detail {
  background-color: #f7f7f7;
}

.article-detail .ad-down .ad-left,
.article-detail .ad-down .ad-right {
  display: inline-block;
  vertical-align: top;
}

/* 上 */
.article-detail > div:first-of-type {
  background: rgba(235, 235, 235, 1);
}
.article-detail .ad-up {
  background: rgba(235, 235, 235, 1);
}
.article-detail .ad-up .nav-bar span {
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(118, 118, 118, 1);
}
.article-detail .ad-up .nav-bar span:not(:first-of-type) {
  margin-left: 5px;
}
.article-detail .ad-up .article-title,
.article-detail .ad-up .author-time,
.article-detail .ad-up .intro {
  text-align: center;
}
.article-detail .ad-up .author-time {
  margin-top: 20px;
}
.article-detail .ad-up .intro {
  margin-top: 20px;
  padding-bottom: 20px;
}
.article-detail .author-time .time {
  margin-left: 100px;
}
.article-detail .article-title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(49, 66, 78, 1);
  margin-top: 50px;
}
/* 下 */
.article-detail .ad-down {
  padding-top: 50px;
  position: relative;
  text-align: left;
}
.dw-center,
.article-detail .ad-down {
  width: 1124px;
  display: inline-block;
}
.dw-center {
  text-align: left;
}
/* 下-左 */
.article-detail .ad-down .ad-left .content-info {
}
.article-detail .ad-down .ad-left .collect-praise-share {
  position: relative;
  height: 90px;
  font-size: 13px;
  margin-top: 100px;
}
.article-detail .ad-down .ad-left .collect-praise-share .collect-praise {
  position: absolute;
  display: inline-block;
  left: 0px;
}
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .collect,
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .praise {
  display: inline-block;
  width: 108px;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(210, 210, 210, 1);
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
}
.article-detail
  .ad-down
  .ad-left
  .collect-praise-share
  .collect-praise
  .praise {
  margin-left: 12px;
  cursor: pointer;
}
.article-detail .ad-down .ad-left .collect-praise-share .share {
  position: absolute;
  display: inline-block;
  right: 0px;
  height: 50px;
  line-height: 50px;
}
.article-detail .ad-down .ad-left .collect-praise-share .share span {
  vertical-align: middle;
  margin-left: 15px;
}
.article-detail .ad-down .ad-left .relation-article .relation-list {
  margin-top: 17px;
  height: 250px;
}

.article-detail .ad-down .ad-left .relation-article .key-word span {
  display: inline-block;
  padding: 2px 10px;
  min-width: 60px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  text-align: center;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .key-word
  span:not(:first-of-type) {
  margin-left: 10px;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .relation-list
  .relation-one {
  display: inline-block;
  width: 155px;
  height: 151px;
  vertical-align: top;
}
.article-detail
  .ad-down
  .ad-left
  .relation-article
  .relation-list
  .relation-one:not(:first-of-type) {
  margin-left: 33px;
}

/* 下-左-相关文章 */

/* 下-右 */
.article-detail .ad-down .ad-right {
  width: 307px;
  margin-left: 40px;
  cursor: default;
}
.article-detail .ad-down .ad-right .author {
  padding: 50px 20px 20px;
  width: 86.5%;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(225, 225, 225, 1);
}
/* 最新文章 */
.newest {
  margin-top: 50px;
}
.newest .bt {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 34px;
}
.newest-list {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(225, 225, 225, 1);
  background: rgba(255, 255, 255, 1);
}
.newest-list .newest-one {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
}
.newest-list .newest-one:hover {
  cursor: pointer;
}

.newest-list .newest-one:not(:first-of-type) {
  padding-top: 20px;
}
.newest-list .newest-one:not(:last-of-type) {
  border-bottom: 1px solid rgba(225, 225, 225, 1);
  padding-bottom: 20px;
}
.release-amount {
  text-align: center;
  border: 1px solid rgba(225, 225, 225, 1);
  margin-top: 50px;
  background: rgba(255, 255, 255, 1);
}
.release-amount > span {
  font-size: 16px;
  font-weight: 300;
  color: rgba(120, 120, 120, 1);
  line-height: 30px;
}
.release-amount > span > span {
  color: rgba(253, 110, 5, 1);
}
/*文章详情标题简介居中*/
.top18-center {
  width: 100%;
}
.article-detail .gotop {
  text-align: center;
  position: fixed;
  right: 50px;
  bottom: 30px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  background: #d6d6d6;
  color: #000;
  transition-duration: 0.5s;
}
.article-detail .gotop:hover {
  transition-duration: 0.5s;
  background: white;
}
/* 文章字体显示样式 */
/* .ad-left .content-wth >>> p {
  line-height: 25px;
  font-size: 16px;
} */
</style>
