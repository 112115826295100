<template>
  <div style="width:100% ; display: inline-block;">
    <!-- 组件开始 -->
    <div class="author-intro">
      <div style="text-align:center;">
        <div class="img">
          <div @click="goToLeaveMesg()">
            <img :src="author.img" alt />
          </div>
        </div>
        <div class="author" @click="goToLeaveMesg()">
          <span>{{author.name}}</span>
          <span class="icon-v"></span>
        </div>
        <div class="degree">
          <span>{{author.degree}}</span>
        </div>
        <div class="intro" v-if="author.intro">
          <span>[{{author.intro}}]</span>
        </div>
        <div class="concern">
          <!-- <span @click="concernMan()" ref="isCtAuthor">+关注</span> -->
          <!-- <span  v-if="follow==2">已关注</span> 
          <span   v-if="follow=1">未关注</span>-->
          <!-- <p :[lists]="a()">{{demo()}}</p> -->
        </div>

        <div class="call" v-if="state">
          <button @click="concernMan()" ref="isCtAuthor">关注</button>
        </div>
      </div>
      <hr />
      <div class="article-list">
        <!-- 当月热门文章 -->
        <div style="text-align:center;width: 100%;" class="type">{{articleType}}</div>
        <div
          @click="goToArticleDetail(artic.id,artic.hotNum)"
          class="aaaaa"
          v-for="(artic,i) in list"
          v-bind:key="artic.id"
        >
          <div class="article-title">
            <p style="word-break:break-all;">{{artic.title}}</p>
          </div>
          <div class="split" v-if="i!=list.length-1">
            <hr />
          </div>
        </div>
      </div>
    </div>
    <!-- 组件结束 -->
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATE, UPDATES, INSERT } from "@/services/dao.js";
import { dataFormat, integralAddOne } from "@/js/common";
export default {
  data() {
    return {
      // concernStatus:true
      // lists: 'er'
      // realAuthor: this.author
      auothorid: 1,
      state: true
    };
  },
  props: {
    img: {
      type: String,
      required: true
    },
    author: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    articleType: {
      type: String,
      required: true
    }
  },
  async created() {
    //获取文章的ID
    let id = null;
    id = this.$route.query.id;
    let info = null;
    //alert(id)
    ///获取发布文章相应用户的ID
    info = await QUERY(
      "post",
      "",
      "  Article(where: {id: {_eq: " + id + "}}) {    authorId  }"
    );
    this.auothorid = info.data.Article[0].authorId;
    // 初始化该用户是否已关注该作者
    this.initCTMan();
  },
  mounted() {
    // 初始化该用户是否已关注该作者
    // this.initCTMan()
  },
  methods: {
    goToLeaveMesg() {
      this.$router.push({
        path: "/authorXX",
        query: {
          id: this.author.id
        }
      });
    },
    async goToArticleDetail(id, hotNum) {
      //    console.log("子页面的list : ")
      //    console.log(this.list)
      // this.$emit('goTop');
      hotNum += 1;
      let update_hotNum = await UPDATES(
        "POST",
        "",
        "update_TtArticle(where:{id:{_eq:" +
          id +
          "}},_set:{hotNum:" +
          hotNum +
          "}) {affected_rows}"
      );
      //    console.log(update_hotNum)
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id
        }
      });

      this.$emit("faoul");
      //    this.$emit("inits")
      // console.log("文章id"+id);

      // console.log("文章id"+id);
      this.$emit("thisMonHot", id);
    },
    // 初始判断该用户是否已关注该作者
    async initCTMan() {
      // 判断用户是否登录
      let isLogin = this.$store.state.userinfo.id;
      // console.log("用户ID： "+isLogin,"作者ID： "+this.auothorid)
      if (isLogin < 0 || isLogin == "" || isLogin == null) {
        // 用户未登录 统一显示+关注
        // console.log('kkkkkkk')
        this.$refs.isCtAuthor.innerText = "+关注";
        this.state = true;
        //alert("请您先登录！")
      } else {
        // 根据查询结果显示是 关注 或 已关注
        // 如果登陆用户是作者本人不显示是否关注
        if (isLogin == this.auothorid) {
          this.state = false;
        } else {
          this.state = true;
          let flag = await QUERY(
            "POST",
            "",
            "TTConcernMan(where:{concernMan:{_eq:" +
              isLogin +
              "},beConcernMan:{_eq:" +
              this.auothorid +
              "}}) {id}"
          );
          // console.log(flag)
          if (flag.data.TTConcernMan.length > 0) {
            this.$refs.isCtAuthor.innerText = "取消关注";
          } else {
            this.$refs.isCtAuthor.innerText = "+关注";
          }
        }
      }
    },
    ///关注、取消关注功能
    async concernMan() {
      let info = null;
      // 判断用户是否登录
      let isLogin = this.$store.state.userinfo.id;
      // console.log("kkk: "+isLogin)
      // console.log("kkk: "+typeof(isLogin))
      if (isLogin == null || isLogin == "" || isLogin < 0) {
        //alert("请您先登录!")
        this.$message({
          message: "请您先登录！",
          type: "warning"
        });
      } else {
        // 如是已登陆用户 就关注或取消关注该作者
        let ctFlag = this.$refs.isCtAuthor.innerText;
        //获取用户的关注数
        info = await QUERY(
          "post",
          "",
          "   PCUsers(where: {id: {_eq: " + isLogin + "}}) { concernNum  }"
        );
        //判断是否关注
        if (ctFlag != "取消关注") {
          // 插入关注数据
          let obj = await INSERT(
            "POST",
            "",
            "insert_TtConcernMan(objects: {beConcernMan: " +
              this.auothorid +
              ", concernMan: " +
              isLogin +
              ', ip: " ", time: "' +
              new Date() +
              '"}) {    affected_rows  }'
          );
          if (obj.data.insert_TtConcernMan.affected_rows == 1) {
            // console.log('关注数'+info.data.PCUsers[0].concernNum)
            // 关注数+1
            info.data.PCUsers[0].concernNum += 1;
            //修改用户的关注数
            let datas = await UPDATE(
              "POST",
              "",
              "  update_User(where: {id: {_eq: " +
                isLogin +
                "}}, _set: {concernNum: " +
                info.data.PCUsers[0].concernNum +
                "}) {  affected_rows }"
            );
            if (datas.data.update_User.affected_rows) {
              // 关注成功
              this.$refs.isCtAuthor.innerText = "取消关注";
               integralAddOne(isLogin);////关注积分加1
            }
          }
        } else {
          // 删除关注数据
          // let obj = await DELETE("POST","",'delete_TtConcernMan(where:{beConcernMan:{_eq:'+this.author.id+'},concernMan:{_eq:'+isLogin+'}}) {    affected_rows  }');
          let obj = await DELETE(
            "POST",
            "",
            " delete_TtConcernMan(where: {beConcernMan: {_eq: " +
              this.auothorid +
              "}, concernMan: {_eq: " +
              isLogin +
              "}}) {    affected_rows  }"
          );
          if (obj.data.delete_TtConcernMan.affected_rows == 1) {
            //    console.log('关注数'+info.data.PCUsers[0].concernNum)
            // 判断用户的关注数
            if (info.data.PCUsers[0].concernNum == 0) {
              info.data.PCUsers[0].concernNum = 0;
            } else {
              info.data.PCUsers[0].concernNum -= 1;
            }
            //返回修改的关注数
            let datas = await UPDATE(
              "POST",
              "",
              "  update_User(where: {id: {_eq: " +
                isLogin +
                "}}, _set: {concernNum: " +
                info.data.PCUsers[0].concernNum +
                "}) {  affected_rows }"
            );
            if (datas.data.update_User.affected_rows) {
              // 取消关注成功
              this.$refs.isCtAuthor.innerText = "+关注";
            }
          }
        }
        // let obj=await INSERT("POST","",'insert_TtConcernMan(objects: {beConcernMan: '+this.author.id+', concernMan: '+this.$store.state.userinfo.id+', ip: "192.168.1.1", time: "'+ new Date()+'"}) {    affected_rows  }');
        // let obj=await INSERT("POST","",'insert_TtConcernMan(objects: {beConcernMan: '+this.author.id+', concernMan: '+this.$store.state.userinfo.id+', ip: "192.168.1.1", time: "'+ new Date()+'"}) {    affected_rows  }');
        // if(obj.data.insert_TtConcernMan.affected_rows==1){
        //     this.concernStatus=false;
        // }else{
        //     this.concernStatus=true;
        // }
        // console.log("关注某人"+obj);
      }
    }
  }
};
</script>

<style scoped>
.author-intro {
  width: 100%;
  text-align: center;
  /* width: 305px; */
  /* background: #fff;
    border: 1px solid #e1e1e1; */
  /* padding: 20px; */
}
.author-intro .img div {
  display: inline-block;
  cursor: pointer;
}
.author-intro .img div img {
  border-radius: 56px;
  height: 83px;
  width: 83px;
}
.author-intro .author .icon {
  background-image: url(../../assets/icon/v.png);
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 8px;
}
.author-intro .author {
  margin-top: 18px;
  cursor: pointer;
}
.author-intro .author span {
  font-size: 16px;
  vertical-align: middle;
  margin-left: 25px;
  font-weight: 300;
}
.author-intro .degree {
  margin-top: 10px;
}
.author-intro .degree span {
  font-size: 14px;
  color: rgba(142, 142, 142, 1);
}
.author-intro .intro span {
  font-size: 14px;
  color: rgba(110, 121, 129, 1);
}
.author-intro .intro {
  margin-top: 18px;
}

.author-intro .concern {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(110, 121, 129, 1);
  margin-top: 25px;
  cursor: pointer;
}
.author-intro .call {
  height: 70px;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
}
.author-intro .call button {
  width: 80px;
  height: 30px;
  background: rgba(235, 97, 0, 1);
  border-radius: 15px;
  color: rgba(251, 252, 253, 1);
  font-size: 14;
  border: 0;
  cursor: pointer;
}

.author-intro .article-list .type {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(117, 117, 117, 1);
  line-height: 30px;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.author-intro .article-list .article-title {
  display: inline-block;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(0, 0, 0, 1);
  line-height: 30px;
  text-align: left;
  width: inherit;
  padding-left: 15px;
  padding-right: 15px;
}
.author-intro .article-list .split {
  height: 20px;
  vertical-align: middle;
  width: 100%;
}
.author-intro .article-list .split hr {
  border: 1px dashed rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.aaaaa:hover {
  cursor: pointer;
}
button:focus {
  outline: none;
  /* border: 1px solid #9ac1f5;
box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44); */
  /* border-width :0 0 1px 0 ; */
}
</style>