<template>
  <div class="uppic-downtext" @click="goToArticleDetail(hotNum)">
    <div class="img" :style="{ height: imgHeight }">
      <img :src="imgUrl" alt="" />
    </div>
    <div class="title" :style="{ fontSize: fontSize }">
      {{ title }}
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATE, UPDATES, INSERT } from "@/services/dao.js";
import { integralAddOne } from "@/js/common/index.js";

export default {
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    imgHeight: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    hotNum: {
      type: Number,
      required: true,
    },
  },
  methods: {
    //打开文章详情

    // goToArticleDetail(){
    //     console.log(this.id);
    //        this.$router.push({path:'/articleDetail', query: {
    //         id: this.id

    async goToArticleDetail(hotNum) {
      this.$emit("handInitCollent");
      // this.$emit('goTop');
      // console.log(this.id,"相关文章",hotNum);

      hotNum += 1;
      let update_hotNum = await UPDATES(
        "POST",
        "",
        "update_TtArticle(where:{id:{_eq:" +
          this.id +
          "}},_set:{hotNum:" +
          hotNum +
          "}) {affected_rows}"
      );

      this.$router.push({
        path: "/articleDetail",
        query: {
          id: this.id,
        },
      });
      this.$emit("faoul");
      // this.$emit("cc");///3.10注释
      // this.$emit('inits')
      if (this.$store.state.userinfo.id ) {//如果登陆了
        let id = this.$store.state.userinfo.id;
        integralAddOne(id); //积分加1
      }
      // console.log("文章id"+this.id);
    },
  },
};
</script>

<style scoped>
.uppic-downtext {
  width: 100%;
  height: 100%;
  cursor: default;
}
.uppic-downtext:hover {
  cursor: pointer;
}
.uppic-downtext .img {
  min-height: 80px;
}
.uppic-downtext .img img {
  height: 100%;
  width: 100%;
}
.uppic-downtext .title {
  margin-top: 8px;
}
</style>
