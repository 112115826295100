<template>
  <div class="lp-comment">
    <div class="comment">
      <div style="font-size:20px">文章点评</div>
      <div class="comment-content">
        <!-- <div v-if="isLogin">
        <span ><span @click="login()"  class="login">登录</span>,参与讨论前请登录</span></div>-->
        <textarea
          id="comment"
          v-model="content"
          @change="change"
          placeholder="请输入评论"
          style="width:100%;height:100%;font-size:15px;padding:3px;resize: none;outline:none;"
        ></textarea>
      </div>
      <div class="operate-bar">
        <!-- <div class="sync-check"> 
                <input type="checkbox"/>
                <span>同步到新浪微博</span>
        </div>-->
        <div class="send-comment">
          <button @click="sendComment()">发表评论</button>
        </div>
      </div>
    </div>

    <div class="article-comment-container" data-item-id="-1" data-type-id="2">
      <div class="words">
        <h3>
          <strong>最新评论</strong>
        </h3>
        <strong style="font-size:20px;  color: #999999;" v-show="replyList.length <= 0">暂无</strong>
        <ul class="comment-say" v-if="replyList">
          <!-- 显示一级评论 -->
          <li
            class="comment-say-li clr comment-level-1"
            v-for="onepcomment in replyList"
            :key="onepcomment.id"
          >
            <div class="csl-img">
              <a rel="nofollow">
                <img :src="onepcomment.authorImg" />
              </a>
            </div>
            <div class="csl-body">
              <div class="cont">
                <div class="row clr">
                  <a rel="nofollow" class="name replyName">{{ onepcomment.authorName }}</a>
                  <span class="time">{{ onepcomment.time }}</span>
                </div>
                <div class="des">{{ onepcomment.content }}</div>
              </div>
              <div class="opBtn">
                <a
                  href="javascript:;"
                  @click="
                    writeBack(
                      onepcomment.commentator,
                      onepcomment.id,
                      onepcomment.id
                    )
                  "
                  class="respond-coin"
                  title="回复"
                >
                  <i />
                  <em>回复</em>
                </a>
                <!-- <span class="zan "> -->
                <!-- <i /> -->
                <!-- <em class="z-count">									({{onepcomment.praiseNum}})								</em> -->
                <!-- </span> -->
              </div>
              <!-- 二次评论 -->
              <div class="respond-submit" v-if="pid == onepcomment.id">
                <div class="text">
                  <input
                    type="text"
                    v-model="content1"
                    placeholder="回复： "
                    style="width:95%;box-sizing: border-box;margin-left:50px "
                  />
                </div>
                <div class="sub clr">
                  <!-- <span class="emotionc">表情</span> -->
                  <button @click="sendChildComment()">发表评论</button>
                  <div class="qqFace" style="position:absolute; display:none; z-index:10"></div>
                </div>
              </div>
              <ul class="csl-respond">
                <!-- 显示二级评论 -->
                <li
                  class="comment-respond-li clr comment-level-2"
                  v-for="onecomment in onepcomment.children"
                  :key="onecomment.id"
                >
                  <div class="crl-img">
                    <a rel="nofollow">
                      <!-- <img src="https://www.leiphone.com/uploads/new/avatar/05/21/89/61_avatar_pic_40_40.jpg?updateTime=1" width="45" height="45"> -->
                    </a>
                  </div>
                  <div class="crl-body">
                    <div class="cont">
                      <!-- <img :src="onecomment.UserauthorImg" width="40" height="40"> -->
                      <a class="name replyName" target="_blank">
                        {{
                        onecomment.UserauthorName
                        }}
                      </a>
                      回复
                      <!-- <img :src="onecomment.authorImg" width="40" height="40">							 -->
                      <a class="name toReplyName" target="_blank">
                        {{
                        onecomment.authorName
                        }}
                      </a>：
                      <div class="dess">{{ onecomment.content }}</div>
                    </div>
                    <div class="opBtn">
                      <a
                        href="javascript:;"
                        @click.stop="
                          writeBacks(
                            onecomment.commentator,
                            onecomment.id,
                            onepcomment.id
                          )
                        "
                        class="respond-coin"
                        title="回复"
                      >
                        <i />
                        <em>回复</em>
                      </a>
                      <!-- <span class="zan "> -->
                      <!-- <i/> -->
                      <!-- <em class="z-count">({{onecomment.praiseNum}})								</em> -->
                      <!-- </span> -->
                      <!-- 三次评论 -->
                      <div class="respond-submit" v-if="pic == onecomment.id">
                        <div class="text">
                          <input
                            v-model="content2"
                            placeholder="回复："
                            type="text"
                            id="answer"
                            style="width: 100%; box-sizing: border-box;"
                          />
                        </div>
                        <div class="sub clr">
                          <!-- <span class="emotionc">表情</span> -->
                          <button @click="sendChildComments(onecomment.UserauthorId)">发表评论</button>
                          <div class="qqFace" style="position:absolute; display:none; z-index:10"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { dataFormat, formatDate, integralAddTwo } from "@/js/common/index.js";
import { DELETE, QUERYED, UPDATE, INSERT } from "@/services/dao.js";
import { YearMMdd } from "@/js/common/index.js";
export default {
  data() {
    return {
      // isLogin:false,
      // isLogined:false,
      // replyList: [],
      parentId: -1,
      pid: -1,
      pic: -1,
      level: 0,
      ancestorId: -1,
      UserID: "",
      content: "",
      content2: "",
      content1: "",
      centers: "" ///中间转换
    };
  },
  props: {
    articleId: {
      type: Number,
      required: true
    },
    replyList: {
      type: Array,
      required: true
    }
  },
  created() {
    this.user = this.$store.state.userinfo.id;
    // this.init();
  },
  methods: {
    /////一级评论
    async change() {
      await this.www(this.content);
      this.content = this.centers; //赋值
    },
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          this.centers = response.data.message.filterContent;
          console.log("赋值成功：" + this.centers);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    //首次评论文章
    async sendComment() {
      //如果勾选了同步到新浪 判断是否是登录了新浪账号
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        //alert("请您先登录！");
        this.$message({
          message: "请您先登录！",
          type: "warning"
        });
      } else {
        //执行评论发表
        // if(this.isLogin){
        let content = "";
        // 文章ID
        let articleId = this.articleId;
        if (
          this.content == "" ||
          this.conten == "undefined" ||
          this.content == null
        ) {
          this.$message({
            message: "评论不能为空",
            type: "warning",
            duration: "1000"
          });
        } else {
          // this.content = document.getElementById("comment").value;
          //添加第一次评论
          await this.www(this.content);
          let info = await INSERT(
            "POST",
            "",
            "   insert_Comment(objects: {whole: " +
              this.articleId +
              ', type: 1, time: "' +
              new Date() +
              '", parentCommentId: 0, isShield: 0,  content: "' +
              this.content +
              '", commentator: ' +
              this.user +
              ", ancestorCommentId: 0}) { affected_rows  }"
          );
          if (info.data.insert_Comment.affected_rows == 1) {
            //
            //查询评论数
            let data = await QUERYED(
              "post",
              "",
              "  Article(where: {id: {_eq: " +
                this.articleId +
                "}}) {   commentNum      }"
            );
            //alert( data.data.Article[0].commentNum)
            //评论数+1
            data.data.Article[0].commentNum += 1;
            //修改文章表中的评论数
            let datdeds = await UPDATE(
              "post",
              "",
              "  update_TtArticle(where: {id: {_eq: " +
                this.articleId +
                "}}, _set: {commentNum: " +
                data.data.Article[0].commentNum +
                "}) { affected_rows }"
            );
            if (datdeds.data.update_TtArticle.affected_rows > 0) {
              this.$message({
                message: "评论成功！",
                type: "success"
              });
              this.content = "";
              this.init();
              let jifen = await QUERYED(
                "post",
                "",
                "Comment(where: {commentator: {_eq: " +
                  this.user +
                  "}}) {  id time }"
              );
              let jf_legth = [];
              for (let item of jifen.data.Comment) {
                if (YearMMdd(item.time) == YearMMdd(new Date())) {
                  jf_legth.push(item);
                }
              }
              if (jf_legth.length <= 10) {
                ////////加两分积分
                integralAddTwo(this.user);
              }
            }
          } else {
            this.$message({
              message: "评论失败！",
              type: "warning"
            });
          }
        }
        // }
      }
      this.pic = -1;
      this.pid = -1;
    },
    //    二次评论
    async sendChildComment() {
      // if(this.isLogin){
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning"
        });
      } else {
        await this.www(this.content1);
        this.content1 = this.centers; //赋值
        let articleId = this.articleId;
        let parentId = this.articleId; //获取被评论的作者ID
        // let content = document.getElementById("answer").value;
        //添加第二次评论
        let info = await INSERT(
          "POST",
          "",
          "   insert_Comment(objects: {whole: " +
            this.articleId +
            ', type: 1, time: "' +
            new Date() +
            '", parentCommentId: ' +
            this.parentId +
            ', isShield: 0,  content: "' +
            this.content1 +
            '", commentator: ' +
            this.user +
            ", ancestorCommentId: " +
            this.ancestorId +
            "}) { affected_rows  }"
        );
        if (info.data.insert_Comment.affected_rows > 0) {
          this.$message({
            message: "回复成功！",
            type: "success"
          });
          this.content1 = "";
          this.init();
        } else {
          this.$message({
            message: "回复失败！",
            type: "warning"
          });
        }
      }
      this.pic = -1;
      this.pid = -1;
    },
    //    三次评论
    async sendChildComments(UserauthorId) {
      // if(this.isLogin){
      if (
        this.user < 0 ||
        this.user == "" ||
        this.user == null ||
        this.user == "undefined"
      ) {
        this.$message({
          message: "请您先登录！",
          type: "warning"
        });
      } else {
        await this.www(this.content2);
        this.content2 = this.centers; //赋值
        let articleId = this.articleId;
        let parentId = this.articleId;
        // let content = document.getElementById("answer").value;
        //添加第二次评论
        let info = await INSERT(
          "POST",
          "",
          "   insert_Comment(objects: {whole: " +
            this.articleId +
            ', type: 1, time: "' +
            new Date() +
            '", parentCommentId: ' +
            this.parentId +
            ', isShield: 0,  content: "' +
            this.content2 +
            '", commentator: ' +
            this.user +
            ", ancestorCommentId: " +
            this.ancestorId +
            "}) { affected_rows  }"
        );
        if (info.data.insert_Comment.affected_rows > 0) {
          this.$message({
            message: "回复成功！",
            type: "success"
          });
          this.content2 = "";
          this.init();
        } else {
          this.$message({
            message: "回复失败！",
            type: "warning"
          });
        }
      }
      this.pic = -1;
      this.pid = -1;
    },
    login() {
      //    if(!this.isLogin){//如果没有登录 跳转到登录页面
      //        this.isLogin=true;
      //    this.$router.push({path:'/articleDetail', QUERYED: {
      //          id: this.articleId
      //    }});
      //    }
      //    this.init();
    },
    //显示评论
    async init() {
      //显示一级评论
      this.replyList.length = 0;
      //根据文章ID，知识头条的一级评论
      let info = await QUERYED(
        "post",
        "",
        "   TTOneComment(where: {whole: {_eq: " +
          this.articleId +
          "}}) {   ancestorCommentId    articleImg  articleTitle    whole   time   parentCommentId   isShield   id   content    commentator   authorName authorImg   authorId  articleintro  }"
      );
      for (let i = 0; i < info.data.TTOneComment.length; i++) {
        let a = info.data.TTOneComment[i];
        a.authorImg = this.api.LoginURL.concat(a.authorImg);
        a.time = formatDate(Date.parse(a.time));
        //显示二级评论
        let info_c = await QUERYED(
          "post",
          "",
          "   TTTwoCommentS(where: {whole: {_eq: " +
            this.articleId +
            "}, ancestorCommentId: {_eq: " +
            a.id +
            "}}) {  type    time   parentCommentId   isShield  id   content   commentator  authorName   authorImg   authorId   articleintro   articleTitle   articleImg  ancestorCommentId  UserauthorImg   UserauthorId   UserauthorName }"
        );
        a.children = info_c.data.TTTwoCommentS;
        a.children.time = formatDate(Date.parse(a.children.time));
        this.replyList.push(a);
      }
    },
    async writeBack(parentId, pid, ancestorCommentId) {
      this.pid = pid;
      this.pic = -1;
      this.parentId = parentId;
      this.ancestorId = ancestorCommentId;
      //alert(this.pid)
    },
    async writeBacks(parentId, pic, ancestorCommentId) {
      this.pic = pic;
      this.pid = -1;
      this.parentId = parentId;
      this.ancestorId = ancestorCommentId;
      //alert(this.pic)
    }
  }
};
</script>

<style scoped>
.lp-comment .comment {
  height: 200px;
}
.lp-comment .comment .comment-content {
  height: 100px;
  margin-top: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
}
.lp-comment .comment .comment-content span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  height: 100%;
  line-height: 66px;
  top: 23px;
  display: inline-block;
}
.lp-comment .comment .comment-content .login {
  color: rgba(253, 110, 5, 1);
  margin-left: 20px;
}
.lp-comment .comment .comment-content .login span {
  cursor: pointer;
}
/* 文章点评操作栏 */
.lp-comment .comment .operate-bar {
  position: relative;
  margin-top: 14px;
}
.lp-comment .comment .operate-bar .sync-check {
  position: absolute;
  left: 0px;
}
.lp-comment .comment .operate-bar .send-comment {
  position: absolute;
  cursor: pointer;
  right: 0px;
}
.lp-comment .comment .operate-bar .send-comment button {
  width: 84px;
  height: 28px;
  background: rgba(253, 110, 5, 1);
  border: 0px solid rgba(253, 110, 5, 1);
  border-radius: 3px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(250, 251, 253, 1);
  cursor: pointer;
}

.lp-comment .words {
  padding-bottom: 0;
}
.lp-comment .words h3 {
  font-size: 14px;
  color: #999999;
  height: 35px;
  line-height: 35px;
  border-bottom: solid 1px #e8e8e8;
  position: relative;
  margin-top: 4px;
  font-weight: normal;
}
.lp-comment .words h3 strong {
  font-size: 16px;
  color: #738a9a;
  position: absolute;
  bottom: -1px;
  left: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.lp-comment .words .comment-say-li {
  padding: 20px 0 8px;
  border-bottom: solid 1px #e6e6e6;
}
.lp-comment .words li,
.lp-comment .jcWords .comment-say-li {
  position: relative;
}
.clr {
  zoom: 1;
}
li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
.lp-comment .words .comment-say .csl-img {
  width: 45px;
  float: left;
  margin-right: 10px;
  /* border: 1px solid red; */
}
a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
a:-webkit-any-link {
  cursor: pointer;
}
.lp-comment .words .comment-say .csl-img img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  vertical-align: top;
}
img {
  border: 0;
}
a {
  color: #333;
  font-size: 100%;
}
/* .lp-comment .words .comment-say .csl-body {
  position: relative;
  border: 1px solid red;
} */
.lp-comment .words .comment-say .csl-body {
  /* padding-left: 60px; */
  /* border: 1px solid green; */
  position: relative;
}
.lp-comment .words .comment-say .csl-body .cont {
  /* border: 1px solid firebrick; */
}
.lp-comment .words .comment-say .csl-body .cont a.name {
  display: inline-block;
}
.lp-comment .words .comment-say .csl-body .cont a.name {
  font-size: 14px;
  color: #31424e;
}
a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.lp-comment .words .comment-say .csl-body .cont .row {
  /* border: 1px solid red; */
  height: 45px;
  line-height: 45px;
}
.lp-comment .words .comment-say .csl-body .cont .row .time {
  font-size: 14px;
  color: #9baab6;
  padding-left: 10px;
  /* border: 1px solid firebrick; */
}
.lp-comment .words .comment-say .csl-body .cont .des {
  /* border: 1px solid darkgoldenrod; */
  margin-left: 45px;
}
.lp-comment .words .comment-say .csl-body .cont .dess {
  /* border: 1px solid forestgreen; */
  display: inline-block;
}
.lp-comment .words .comment-say .csl-body .cont .des,
.lp-comment .jcWords .comment-say .csl-body .cont .des {
  font-size: 14px;
  color: #5e6b73;
  line-height: 24px;
  display: inline-block;
}
.lp-comment .comment-say .csl-body .opBtn {
  font-size: 12px;
  color: #cccccc;
  height: 14px;
  line-height: 14px;
  font-style: inherit !important;
  position: relative;
  top: -12px;
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin i {
  display: inline-block;
  /* background: url(../../images/common/yp_spirit.png) no-repeat -130px -270px; */
  width: 14px;
  height: 13px;
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin em {
  color: #aaaaaa;
  font-size: 13px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
.lp-comment .comment-say .csl-body .opBtn .zan {
  display: inline-block;
  /* float: right; */
  position: relative;
  top: -2px;
}
.lp-comment .comment-say .csl-body .zan i {
  display: inline-block;
  cursor: pointer;
  /* background: url(../../images/common/yp_spirit.png) no-repeat -130px -245px; */
  width: 13px;
  height: 13px;
}
i {
  font-style: normal;
}
.lp-comment .comment-say .csl-body .zan em {
  position: relative;
  top: -1px;
  font-size: 14px;
}
.lp-comment .comment-say .csl-respond {
  /* padding: 10px 0 0px; */
  /* border: 1px solid firebrick; */
}
.lp-comment .words .comment-say .comment-respond-li {
  /* border: 1px solid red; */
  /* padding: 20px 0; */
  /* border-top: 1px solid #e6e6e6; */
}
.lp-comment .words li,
.lp-comment .jcWords .comment-say-li {
  position: relative;
}
.lp-comment .words .comment-say .crl-img {
  width: 45px;
  float: left;
}
.lp-comment .words .comment-say .crl-body {
  padding-left: 65px;
}
.lp-comment .words .comment-say .crl-body .cont {
  color: #666666;
  font-size: 14px;
  /* padding-bottom: 10px; */
}
.lp-comment .comment-say .csl-body .opBtn a.respond-coin {
  float: right;
}
.lp-comment .comment-say .csl-body .opBtn span {
  padding-right: 22px;
  float: left;
}
.lp-comment .words .comment-say .csl-body .text {
  position: relative;
}
.lp-comment .words .comment-say .csl-body .text input {
  border-radius: 2px;
  padding-right: 10px;
  height: 37px;
  line-height: 14px;
  font-size: 14px;
  line-height: 37px\9;
  color: #5a5a5a;
  border: solid 1px #eeeeee;
  background: #f7f7f7;
}
.lp-comment .words .comment-say .csl-body .text .tip {
  font-size: 14px;
  color: #999999;
  position: absolute;
  top: 10px;
  left: 42px;
}
.lp-comment .words .comment-say .csl-body .text .tipS {
  font-size: 14px;
  color: #999999;
  position: absolute;
  top: 25px;
  left: 2px;
  /* border: 1px solid red; */
}
.lp-comment .words .comment-say .csl-body .text .tip a {
  padding: 0 5px;
}
.lp-comment .words .comment-say .csl-body .sub {
  padding-top: 10px;
}
.lp-comment span.emotionc {
  font-size: 12px;
  color: #666;
  width: 42px;
  height: 20px;
  /* background: url(../../images/common/lph-comment-face.gif) no-repeat 2px 2px; */
  padding-left: 21px;
  cursor: pointer;
  margin-right: 10px;
}
.lp-comment span.emotionc {
  /* background: url(../../images/face.png) 0 0 no-repeat; */
  width: 21px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  text-indent: -999em;
}
button {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: buttonface;
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}
.lp-comment .words .comment-say .csl-body .sub button {
  float: right;
  font-family: Microsoft Yahei;
  width: 100px;
  height: 40px;
  line-height: 40px;
  right: 0;
  top: 48px;
  text-align: center;
  background: #ea7754;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.lp-comment .qqFace {
  margin-top: 4px;
  background: #fff;
  padding: 2px;
  border: 1px #dfe6f6 solid;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}
em {
  font-style: normal;
}

input[type="text"]:focus {
  border: solid 0.007353rem #eeeeee;
  outline: 0;
  box-shadow: 0 0 10px #9ecaed;
}
/* .lp-comment .words .comment-say .csl-body .text .tip{
  top: 27px;
} */
button:focus {
  outline: none;
  /* border: 1px solid #9ac1f5;
box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44); */
  /* border-width :0 0 1px 0 ; */
}
</style>
